import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { delay } from '../../../utils/helps.service';

@Component({
    selector: 'lib-tw-nfc-scanner',
    templateUrl: './nfc-scanner.component.html',
    styleUrls: ['./nfc-scanner.component.css']
})
export class NfcScannerComponent implements OnInit {

    @Output() barcodeData = new EventEmitter<any>();
    @Input() startScannerBarcode = false;
    barcode = '';
    // @ts-ignore
    private reader: any;
    scanning = false;
    test: string;
    dec;
    record;
    recordType;
    private abortController: AbortController;
    data;
    supportNfc = false;


    constructor() {
    }

    async ngOnInit() {
        if (this._supportNFC()) {
            await this.scanTag();
        }


    }

    _supportNFC() {
        if (!('NDEFReader' in window)) {
            this.supportNfc = false;
            return false;
        }
        this.supportNfc = false;
        return true;
    }

    scanTag = () => {
        return new Promise((resolve, reject) => {
            try {

                // @ts-ignore
                this.reader = new NDEFReader();
                this.abortController = new AbortController();
                this.reader.scan({ signal: this.abortController.signal });
                this.scanning = true;
                this.reader.addEventListener('reading', async ({ message, serialNumber }) => {

                    serialNumber = String(serialNumber);
                    serialNumber = serialNumber.replaceAll(':', '');
                    //  this.barcode = '1' + serialNumber;
                    await this.gerBarcode(serialNumber);
                    resolve(this.readTag(message));
                });


                this.reader.addEventListener('readingerror', e => {
                    console.log('error reading tag', e);
                    reject(e);
                });


            } catch (e) {
                console.log('error scanning tag:', e);
                this.scanning = false;

                reject(e);
            }
        });
    };

    readTag(message) {
        const { records } = message;
        this.record = JSON.stringify(message);
        return records.map(record => {
            const { id, recordType, mediaType, encoding, data } = record;


            const decoder = encoding ? new TextDecoder(encoding) : new TextDecoder();
            // this.dec = decoder;
            this.recordType = recordType;
            switch (recordType) {
                case 'url':
                case 'text':
                    console.log('data', decoder.decode(data));
                    break;

                case 'mime':
                    this.data = JSON.parse(decoder.decode(data));
                    this.showTagData(JSON.parse(decoder.decode(data)));


                    break;
            }

            return ['url', 'text'].includes(recordType) ? decoder.decode(data) : JSON.parse(decoder.decode(data));
        });
    }

    capitalize = string => `${string.substr(0, 1).toUpperCase()}${string.substr(1)}`;

    showTagData(data) {
        this.test = [...Object.entries(data)].reduce((html, [key, value]) => `${html}${this.capitalize(key)}: ${value}`, ``);
    }

    async gerBarcode(barcode) {

        if (barcode && barcode.length > 4 && this.startScannerBarcode) {
            this.startScannerBarcode = false;
            await delay(700);
            this.barcodeData.next(barcode);
            this.barcode = barcode;
        }
    }
}
