import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationUserService } from './authentication-user.service';
import { PermissionEnum, PermissionUserService } from './permission-user.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigRouter } from '../utils/config-router';

@Injectable({
    providedIn: 'root'
})
export class PermissionMenuGuard implements CanActivate {


    constructor(private authService: AuthenticationUserService,
                private per: PermissionUserService,
                private router: Router,
                private translate: TranslateService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
         console.log(next.routeConfig.path)
        switch (next.routeConfig.path) {
            case ConfigRouter.DISPOSITION :
                if (!this.per.userRightAccess(PermissionEnum.ADMIN_DISPOSITIONS)
                    && !this.per.userRightAccess(PermissionEnum.GODMODE)) {
                    this.router.navigate([this.translate.currentLang + '/not-authorized']);
                    return false;
                }
                break;
            case ConfigRouter.HISTORIC_SCAN :
                if (!this.per.userRightAccess(PermissionEnum.SCAN_DEBUG)
                    && !this.per.userRightAccess(PermissionEnum.GODMODE)) {
                    this.router.navigate([this.translate.currentLang + '/not-authorized']);
                    return false;
                }
                break;
            default:
                return true;
        }
        return true;
    }

}
