import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguageComponent} from './language/language.component';
import {TranslateModule} from "@ngx-translate/core";

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

export * from './language/language.component';
@NgModule({
    declarations: [LanguageComponent],
    exports: [
        LanguageComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
    ]
})
export class LanguageModule {
}
