import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { PROJECT_NAME, ProjectNameEnum } from '../utils/utilsShared';


export class Subdomain {
    constructor(public url: string, public subdomain: string) {
    }
}


const SUBDOMAIN: Subdomain[] = [
    new Subdomain('billetterieimperialbell.com', 'imperialbell'),
    new Subdomain('www.billetterieimperialbell.com', 'imperialbell'),
    new Subdomain('bfvq.billetterieimperialbell.com', 'bfvq'),
    new Subdomain('billetteriefeq.ca', 'billetterie'),
    new Subdomain('www.billetteriefeq.ca', 'billetterie'),
    new Subdomain('pos', null)
];

@Injectable({
    providedIn: 'root'
})
export class SubdomainService {
    url: string;

    constructor( @Optional() @Inject(PROJECT_NAME) private projectName: ProjectNameEnum) {
    }


    getSubdomainObserval(): Observable<string> {
        const host = window.location.hostname;
        if (!host) {
            return of(null);
        }
        const sub = SUBDOMAIN.find(subdomain => subdomain.url === host);
        if (!sub) {
            const temp = host.split('.');
            if (temp.length === 3) {
                return of(temp[0]);
            }
            return of(null);
        }
        return of(sub.subdomain);
    }

    setUrl(url: string) {
        if (!url) {
            return;
        }
        this.url = url;
    }

    getSubdomain(url?: string): string {


        let host = window.location.hostname;

        const urlTemp = new URL(window.location.href);
        const paramSubdomain = urlTemp.searchParams.get('subdomain');
        if (paramSubdomain) {
            return paramSubdomain;
        }

        if (url) {
            host = url;
        }
        if (!host) {
            return '';
        }
        const sub = SUBDOMAIN.find(subdomain => subdomain.url === host);
        //console.log(sub)
        if (!sub) {
            const temp = host.split('.');
            // console.log(temp)
            if (temp.length === 3) {

                return temp[0];
            }
            return '';
            //  return 'www';
            //return 'jungleisland';
        }
        return sub.subdomain;
    }

    getGwtUrl() {
        return `${environment.urlGwt.replace('%1', `${this.getSubdomain()}.`)}`;
    }
}
