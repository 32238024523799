import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotAuthorizedComponent } from './notauthorized/not-authorized.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { EmptyComponent } from './empty/empty.component';

 const routes: Routes = [
    {
        path: '',
        component: NotAuthorizedComponent
    },
     {
         path: 'empty',
         component: EmptyComponent
     }
];

@NgModule({
    declarations: [NotAuthorizedComponent, EmptyComponent],
    exports: [NotAuthorizedComponent,EmptyComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
    ]
})
export class NotAuthorizedModule {
}
