import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsListComponent } from './accounts-list/accounts-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {ReactiveFormsModule} from "@angular/forms";
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
    declarations: [AccountsListComponent],
    exports: [
        AccountsListComponent
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule
  ]
})
export class AccountsModule { }
