import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tw-dialog-msg',
  templateUrl: './dialog-msg.component.html',
  styleUrls: ['./dialog-msg.component.css']
})
export class DialogMsgComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogMsgComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any

  ) {

  }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
