import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';

import { Params } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe, Location } from '@angular/common';
import { ComponentRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { from, lastValueFrom } from 'rxjs';
import { getGeolocation } from './utilsShared';
import { IpApiGeo } from '../models/ip-api-geo';
import { Sta } from '../models/address';
import { constSystem } from './const-system';
import { map } from 'rxjs/operators';

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

@Injectable({
    providedIn: 'root'
})
export class HelpsService {

    constructor(public l: Location, public http: HttpClient) {
    }


    static formtUrlImage(imageUrl): string {
        if (imageUrl && imageUrl.search('http') === -1) {
            imageUrl = environment.urlApi + imageUrl.substr(1, imageUrl.length);
        }
        return imageUrl;
    }

    static dateEqual(date: Date, date2: Date): boolean {
        return date.getFullYear() === date2.getFullYear() && date.getMonth() === date2.getMonth() && date.getDate() === date2.getDate();
    }

    static createComponent(T, viewContainerRef: ViewContainerRef, componentFactoryResolver: ComponentFactoryResolver): ComponentRef<any> {
        if (!viewContainerRef) {
            return;
        }
        if (viewContainerRef) {
            viewContainerRef.clear();
        }

        const factory = componentFactoryResolver.resolveComponentFactory(T);
        return viewContainerRef.createComponent(factory);
    }

    static createHttpParams(listPram: Params, httpParams?: HttpParams, skip?: string): HttpParams {
        if (!httpParams) {
            httpParams = new HttpParams();
        }
        Object.keys(listPram).forEach(value => {
            if (skip !== value) {
                httpParams = httpParams.set(value, listPram[value]);
            }
        });
        return httpParams;
    }

    static injectScript(src: string) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.async = true;
            script.src = src;
            script.addEventListener('load', resolve);
            script.addEventListener('error', () => reject('Error loading script.'));
            script.addEventListener('abort', () => reject('Script loading aborted.'));
            document.head.appendChild(script);
        });
    }

    static dateToString(val: Date): string {
        const dt = new DatePipe('en-US');
        const s = dt.transform(val, 'yyyy-MM-ddTHH:mm:ssZ');
        if (s) {
            return s.substring(0, 19);
        }
        return null;
    }

    static dateToStringWithoutTimezone(date) {
        const dt = new DatePipe('en-US');
        let s = dt.transform(date, 'yyyy-MM-ddTHH:mm:ss');
        s = String(s + '-04:00');
        return s;
    }

    static getHeadForAgGrid(list) {
        const columnDefs = [];
        if (!list || list.length === 0) {
            return columnDefs;
        }
        const obj = list[0];
        const keys = Object.keys(obj);
        keys.sort();

        keys.forEach(function(element) {
            const headerNameFormat = element.split('__');
            columnDefs.push({
                    headerName: headerNameFormat[1],
                    field: element.toLowerCase(),
                    width: 200
                }
            );
        });
        return columnDefs;
    }

    static getUrlParams(): any {
        const params = {};
        window.location.search.substring(1).split('&').filter(str => str.length > 0).forEach(paramString => {
            const equalSignIndex = paramString.indexOf('=');
            params[paramString.substring(0, equalSignIndex)] = paramString.substring(equalSignIndex + 1);
        });

        console.log('params', params);

        return params;
    }

    static getValueParam(key: string): any {
        if (!key) {
            return null;
        }
        const temp = HelpsService.getUrlParams();
        if (Object.keys(temp).length === 0) {
            return null;
        }

        return temp[key];
    }

    static findTextValue(val: string, search: string) {
        if (!val) {
            return false;
        }
        return val.toLowerCase().search(search.toLowerCase()) !== -1;

    }


    selectChange(val) {
        let url = window.location.href;


        const temp = this.l.path();
        const arr = temp.split('?');
        if (val === 'fr') {
            url = url.replace('en', val);
        }
        if (val === 'en') {
            url = url.replace('fr', val);
            arr[0] = arr[0].replace('fr', val);
        }
        window.open(url, '_self');
    }

    async getGeoProvince() {

        let geolocationPosition: GeolocationPosition;
        let detail;
        try {
            geolocationPosition = await lastValueFrom(from(getGeolocation()));
            let httpParams = new HttpParams();
            httpParams = httpParams.set('latitude', String(geolocationPosition.coords.latitude));
            httpParams = httpParams.set('longitude', String(geolocationPosition.coords.longitude));
            detail = await lastValueFrom(this.http.get<IpApiGeo>('v1/l12n/city', { params: httpParams }));
            return { city: detail.city, ...this.findProvince(detail.province) };


        } catch (e) {
            console.log(e);
        }
        if (!detail) {

            const ipApiGeo: IpApiGeo = await lastValueFrom(this.http.jsonp<IpApiGeo>('https://geolocation-db.com/jsonp/a9e48c70-8b22-11ed-8d13-bd165d1291e3', 'callback')
                .pipe(map(res => {
                    return res.results.map(item => item);
                }))
            );
            return { city: ipApiGeo.city, ...this.findProvince(ipApiGeo.state) };

        }
        return null;
    }


    validProvinceCanada(province: string): string {
        const a = this.findProvince(province);
        return a === undefined ? null : a.ab;
    }

    findProvince(province: string): Sta {
        if (!province) {
            return null;
        }
        const list = <Sta[]>constSystem.world.provinces;
        const a = list.find(value => value.ab === province.toUpperCase() ||
            value.nameEn.toLowerCase() === province.toLowerCase() || value.nameFr.toLowerCase() === province.toLowerCase());
        return a;
    }


}
