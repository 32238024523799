import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {lastValueFrom, Observable, ReplaySubject, Subject} from 'rxjs';

import {take, takeUntil} from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';
import {HttpClient, HttpParams} from '@angular/common/http';

@Component({
    selector: 'lib-list-entity',
    templateUrl: './list-entity.component.html',
    styleUrls: ['./list-entity.component.css']
})
export class ListEntityComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('singleSelect') singleSelect: MatSelect;
    @Output() entityChange = new EventEmitter();
    @Input() url;
    @Input() httpParameters = new HttpParams();
    @Input() listElements: any[];
    @Input() hasDefaultSelect = true;
    @Input() filtersKey: string[] = ['name'];
    @Input() label: any;
    @Input() labelTitle: any;
    entities: any[] = [];


    idValue;
    @Output() idChange = new EventEmitter();

    @Input()
    set id(val) {
        this.idValue = val;
        this.idChange.emit(this.idValue);
    }


    entityCtrl: UntypedFormControl = new UntypedFormControl();
    entitiesFilterCtrl: UntypedFormControl = new UntypedFormControl();
    filteredEntities: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    load = true;
    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    constructor(private http: HttpClient) {
    }

    async ngOnInit() {
        this.load = false;
        if (!this.listElements) {
            const temp = await lastValueFrom(this.getEntities());
            console.log(Array.isArray(temp));
            if (Array.isArray(temp)) {
                this.entities = temp;
            } else {
                this.entities = temp[Object.keys(temp)[0]];
            }


        } else {
            this.entities = this.listElements;
        }

        const t = this.entities.find(value => value.id === this.idValue);
        this.load = true;

        //console.log(this.entities.slice())
        this.filteredEntities.next(this.entities.slice());
        this.entityCtrl.valueChanges.subscribe(value => {
            console.log(value);
            this.addSelected(value);
        });
        // listen for search field value changes
        this.entitiesFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterBanks();
            });
        if (t) {
            this.entityCtrl.setValue(t);

        } else {
            if (this.hasDefaultSelect) {
                this.entityCtrl.setValue(this.entities[0]);
            }


        }


    }

    addSelected(value) {
        if (value) {
            this.idValue = value.id;
        } else {
            this.idValue = value;
        }

        this.idChange.emit(this.idValue);
        this.entityChange.emit(value);
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    protected setInitialValue() {
        this.filteredEntities
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
                console.log(this.singleSelect);
            });
    }

    protected filterBanks() {
        if (!this.entities) {
            return;
        }
        // get the search keyword
        let search = this.entitiesFilterCtrl.value;
        if (!search) {
            this.filteredEntities.next(this.entities.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        let neList = [];
        this.filtersKey.forEach(value => {
            if (neList.length === 0) {
                neList = this.entities.filter(bank => bank[value].toLowerCase().indexOf(search) > -1);
            }

        });

        this.filteredEntities.next(
            neList
        );
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getEntities(): Observable<any> {
        console.log(this.url);
        return this.http.get<any>(this.url, {params: this.httpParameters});
    }

}
