import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';

import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {AuthenticationUserService, LangsObserverService} from '../../../shared-lib/src/lib/services';
import {HelpsService} from '../../../shared-lib/src/lib/utils/helps.service';
import {PermissionEnum, PermissionUserService} from '../../../shared-lib/src/lib/services/permission-user.service';
import {ThemeService} from '../../../shared-lib/src/lib/services/theme.service';
import {environment} from '@environments';
import {HttpParams} from '@angular/common/http';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    showMenu = false;
    hasAuthenticateUser = false;
    permissionCapacity = PermissionEnum.SCAN_CAPACITY;
    ev = environment;
    accountSelected = {
        id: null
    };

//{"created":"2019-04-26T20:39:24.304+0000","refreshToken":"TovbXVOLPzOMRftTsEvXpXrV","expiration":"2019-04-28T20:39:24.303+0000"}
    user: any;
    httpParams = new HttpParams().set('context', 'ADMIN');

    constructor(private router: Router, private langsObserverService: LangsObserverService,
                private authenticationUserService: AuthenticationUserService,
                public translate: TranslateService
        , private permissionUserService: PermissionUserService,
                public per: PermissionUserService) {


    }

    ngOnInit() {
        const accountSelected = this.authenticationUserService.getAccountSelected();
        if (accountSelected) {
            this.accountSelected = accountSelected;
        }

        ThemeService.changeTheme(<any>{});
        //this.authenticationUserService.addSessionRefreshToken({...activatedRoute.snapshot.queryParams});
        const urlParams = HelpsService.getUrlParams();
        if (urlParams && Object.keys(urlParams).length > 0 && urlParams['refreshToken']) {
            this.authenticationUserService.addSessionRefreshToken(urlParams);
        }
        this.hasAuthenticateUser = !this.authenticationUserService.isExpirationRefreshToken();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            // do something
            console.log(event.lang);
            this.langsObserverService.addLang(event.lang);
        });

        this.authenticationUserService.watchActionAuth().subscribe(() => {
            if (!this.authenticationUserService.isExpirationRefreshToken()) {
                this.showMenu = true;
                this.hasAuthenticateUser = true;
               // this.goUserRoute('dispositions');

            } else {
                this.hasAuthenticateUser = false;
                this.showMenu = false;
            }
        });
        this.showMenu = !this.authenticationUserService.isExpirationRefreshToken();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

        });
        this.user = this.authenticationUserService.getUserFromSession();
        setTimeout(() => {
            if (!this.authenticationUserService.isExpirationRefreshToken()) {
                this.showMenu = true;
                this.hasAuthenticateUser = true;

                  //this.goUserRoute('dispositions');

            } else {
                this.showMenu = false;
                this.hasAuthenticateUser = false;
            }
        }, 1000);


    }


    goUserRoute(val) {
        if (val === 'logout') {
            this.authenticationUserService.logoutUser().subscribe(() => {

                this.router.navigate([this.translate.currentLang + '/login']
                ).then(() => {
                    //this.closeNav();
                });
            });
        } else if (this.showMenu) {
            this.router.navigate([this.translate.currentLang + '/' + val]).then(() => {
                // this.closeNav();
            });

        }
    }

    accountSelectChange(account: any) {
        // console.log(account)
        if (account) {
            this.authenticationUserService.addAccountSelected(account);
        }

    }
}
