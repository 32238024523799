import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NfcScannerComponent } from './nfc-scanner/nfc-scanner.component';



@NgModule({
  declarations: [
    NfcScannerComponent
  ],
  exports: [
    NfcScannerComponent
  ],
  imports: [
    CommonModule
  ]
})
export class NfcScannerModule { }
