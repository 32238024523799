<!--The content below is only a placeholder and can be replaced.-->
<!--&lt;!&ndash;The content below is only a placeholder and can be replaced.&ndash;&gt;-->

<!--<div id="tw-body" class="tw-overlay" (click)="closeNav()"></div>-->
<!--<div id="sidenav" class="tw-sidenav mat-elevation-z1" >-->
<!--    <a class="tw-closebtn" (click)="closeNav()">&times;</a>-->
<!--    <a (click)="goUserRoute('logout')">Logout</a>-->
<!--    <a (click)="goUserRoute('scanner/events')">Scanner</a>-->


<!--</div>-->

<!--<span style="font-size:30px;cursor:pointer" id="menuIcon" (click)="openNav()" *ngIf="showMenu">&#9776;</span>-->
<!--<div  >-->
<!--    <div style="width: 100%;text-align: right">-->
<!--        &lt;!&ndash;        <tw-langs  [languageSupported]="['fr','en']" appType="report"></tw-langs>&ndash;&gt;-->
<!--    </div>-->
<!--   <mat-icon>home</mat-icon>-->
<!--    <router-outlet></router-outlet>-->
<!--</div>-->
<lib-message></lib-message>
<!--{{hasAuthenticateUser}}-->
<div>
    <mat-toolbar class="mat-elevation-z8" style="height: 100px">
        <mat-toolbar-row style="height: 100px">


            <button
                mat-icon-button
                *ngIf="sidenav.mode === 'over'"
                (click)="sidenav.toggle()"
            >
                <mat-icon *ngIf="!sidenav.opened"> menu</mat-icon>
                <mat-icon *ngIf="sidenav.opened"> close</mat-icon>
            </button>
            <div style="
height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

">
                <img width="32" height="32"  src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/logo/ticketpro-icon.png" alt="icontkp">
            </div>
            <lib-list-entity *ngIf="hasAuthenticateUser"
                [(id)]="accountSelected.id"
                url="v1/accounts"
                hasDefaultSelect="false"
                [httpParameters]="httpParams"
                label="name"
                [filtersKey]="['name']"
                labelTitle="{{ 'account' | translate}}"
                (entityChange)="accountSelectChange($event)"
            ></lib-list-entity>


        </mat-toolbar-row>






    </mat-toolbar>
<!--    <mat-progress-bar-->
<!--        *ngIf="navService.name.key==='scan-view' "-->
<!--        [mode]="actionService.progress.mode" [color]="actionService.progress.color" [value]="actionService.progress.val"></mat-progress-bar>-->


    <mat-sidenav-container>
        <mat-sidenav


            #sidenav="matSidenav" class="admin-sidenav" style="bottom:50px !important; ">
            <ng-container *ngIf="hasAuthenticateUser">
                <!--                <img-->
                <!--                    class="avatar mat-elevation-z8"-->
                <!--                    src=""-->
                <!--                />-->
                <mat-icon>account_circle</mat-icon>



                <h4 class="name">{{user?.username}}</h4>
                <p class="designation">{{user?.lastname}} {{user?.firstname}}</p>
                <p class="designation">{{user?.email}} </p>
                <mat-divider></mat-divider>

                <button mat-button class="menu-button" [routerLink]="[ './'+translate.currentLang+'/dispositions' ]" queryParamsHandling="merge"

                >
                    <mat-icon>view_list</mat-icon>
                    <span>dispositions</span>
                </button>

                <button mat-button class="menu-button" [routerLink]="[ './'+translate.currentLang+'/websites' ]" queryParamsHandling="merge">
                    <mat-icon>manage_search</mat-icon>
                    <span>Websites</span>
                </button>
                <button mat-button class="menu-button" [routerLink]="[ './'+translate.currentLang+'/pages' ]" queryParamsHandling="merge">
                    <mat-icon>manage_search</mat-icon>
                    <span>Pages</span>
                </button>
                <button mat-button class="menu-button" [routerLink]="[ './'+translate.currentLang+'/locations' ]" queryParamsHandling="merge">
                    <mat-icon>manage_search</mat-icon>
                    <span>locations</span>
                </button>



                <mat-divider></mat-divider>

                <button mat-button class="menu-button" routerLink="/help" queryParamsHandling="merge">
                    <mat-icon>help</mat-icon>
                    <span>Help</span>
                </button>

                <button mat-button class="menu-button" (click)="goUserRoute('logout')">
                    <mat-icon>logout</mat-icon>
                    <span>logout</span>
                </button>
            </ng-container>
            <ng-container *ngIf="!hasAuthenticateUser">
                <button mat-button class="menu-button" [routerLink]="[ './'+translate.currentLang+'/login' ]" queryParamsHandling="merge">
                    <mat-icon>login</mat-icon>
                    <span>Login</span>
                </button>
                <button mat-button class="menu-button" [routerLink]="[ './'+translate.currentLang+'/help' ]" queryParamsHandling="merge">
                    <mat-icon>help</mat-icon>
                    <span>Help</span>
                </button>

            </ng-container>

            <tw-language  [languageSupported]="['fr','en']" appType="sale"></tw-language>

            <div style="
                text-align: center;
                    position: absolute;
                    bottom: 0;
                    width: 77%;
                ">
                <img width="100px" src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/logo/1-logo-regulier-regular/svg/logo-horizontal-eniter-blanc-all-white.svg" alt="cartlogo">
            </div>

        </mat-sidenav>
        <mat-sidenav-content [ngClass]="ev.production ? '':'background-staging'">
            <div class="content " style="">
                <div style="text-align: center" *ngIf="!hasAuthenticateUser">
                    <img width="300px" src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/logo/1-logo-regulier-regular/svg/logo-horizontal-couleur-color-RGB.svg" alt="cartlogo">

                </div>

                <router-outlet></router-outlet>
                <!--                <ng-container  *ngIf="versionObserver">-->
                <!--                    <pre> {{versionObserver | json}}</pre>-->
                <!--                </ng-container>-->
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>


