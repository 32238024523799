import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '../../../shared-lib/src/lib/component/page-not-found/page-not-found.component';
import { AuthenticationUserGuard } from '../../../shared-lib/src/lib/services';
import { PermissionMenuGuard } from '../../../shared-lib/src/lib/services/permission-menu.guard';
import { ConfigRouter } from '../../../shared-lib/src/lib/utils/config-router';
import { AppResolveService } from '../../../shared-lib/src/lib/services/app-resolve.service';


export const routes: Routes = [
    {
        path: ':lang',
        resolve: {
            data: AppResolveService
        },
        children: [

            {
                path: 'login',
                loadChildren: () => import('../../../../projects/shared-lib/src/lib/component/users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'not-authorized',
                loadChildren: () => import('../../../../projects/shared-lib/src/lib/component/notauthorized/not-authorized.module').then(m => m.NotAuthorizedModule)
            },
            {
                path: '',
                canActivate: [AuthenticationUserGuard],
                children: [
                    {
                        path: ConfigRouter.DISPOSITION,
                        canActivate: [PermissionMenuGuard],
                        loadChildren: () => import('../modules/disposition/disposition.module').then(m => m.DispositionModule)
                    }
                    , {
                        path: ConfigRouter.scanner,
                        canActivate: [PermissionMenuGuard],
                        loadChildren: () => import('../../../../projects/scan-app/src/modules/scan/scan.module').then(m => m.ScanModule)
                    }
                    ,
                    {
                        path: ConfigRouter.SEAT_MAP + '/:id',
                        loadChildren: () => import('../modules/editor-seats-map/editor-seats-map.module').then(m => m.EditorSeatsMapModule)
                    },

                    {
                        path: ConfigRouter.external_barcode,
                        loadChildren: () => import('../modules/manager-external-bracode/manager-external-barcode.module').then(m => m.ManagerExternalBarcodeModule)
                    },
                    {
                        path: ConfigRouter.pages,
                        loadChildren: () => import('../modules/page-ad/page-ad.module').then(m => m.PageAdModule)
                    },
                    {
                        path: ConfigRouter.locations,
                        loadChildren: () => import('../modules/locations/locations.module').then(m => m.LocationsModule)
                    },
                    {
                        path: ConfigRouter.websites,
                        loadChildren: () => import('../modules/website-ad/website-ad.module').then(m => m.WebsiteAdModule)
                    }
                ]
            },
            { path: '', redirectTo: '/en/login', pathMatch: 'full' },
            { path: '**', component: PageNotFoundComponent }
        ]
    },
    { path: '', redirectTo: '/en/login', pathMatch: 'full' },
    { path: '**', redirectTo: '/en/login' },
    { path: 'page-not-fount', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingAdminModule {
}
