import {Component, Host, Input, OnChanges, OnInit} from '@angular/core';

import {ValidationErrors} from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import {FormErrorContainer} from "../../models/form-error-container";

@Component({
    selector: 'tw-form-error-message',
    templateUrl: './form-error-message.component.html',
    styleUrls: ['./form-error-message.component.css']
})
export class FormErrorMessageComponent implements OnInit, OnChanges {
    displayedFormErrors: string[] = [];
    @Input() customFormErrors: FormErrorContainer = {} as {
        required: string,
        minlength: string,
        maxlength: string,
        min: string,
        max: string,
        email: string,
        pattern: string,
        cardValidator: string,
        equalValidator: string,
        validateEmail: string
    }
    @Input() errors: ValidationErrors;

    constructor(@Host() parent: MatFormField) {
    }

    ngOnInit() {
    }

    getErrorMessage(errorKey: string) {
        if (this.customFormErrors[errorKey]) {
            // if error message is overridden in html tag with custom messages
            return (this.customFormErrors[errorKey]);
        } else if (this.errors[errorKey].message) {
            // if it's a custom validation error with multiple possible messages
            return this.errors[errorKey].message;
        } else {
            return `formMsg.errors.${errorKey}`;
        }
    }

    ngOnChanges() {
        this.displayedFormErrors = [];
        if (this.errors) {
            if (this.errors.required) {
                this.displayedFormErrors.push(this.getErrorMessage('required'));
                return;
            }
            for (const errorKey in this.errors) {
                if (this.errors[errorKey]) {
                    this.displayedFormErrors.push(this.getErrorMessage(errorKey));
                }
            }
        }
    }
}
