import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {SessionLocaleService} from './session-locale.service';
import {map} from 'rxjs/operators';
import {RefreshTokenResponse} from '../models/refresh-token-response';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationUserService {
    private subjectRqt = new Subject<any>();

    accountSub = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient,
                private sessionLocaleService: SessionLocaleService) {
    }

    watchActionAuth(): Observable<any> {
        return this.subjectRqt.asObservable();
    }

    loginUser(body: any, httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v2/refresh-tokens', body, {params: httpParams}).pipe(
            map(value => {
                this.addSessionRefreshToken(value);

                this.subjectRqt.next('ok');
            })
        );
    }

    getUser(idUser: any, httpParams ?: HttpParams): Observable<any> {
        return this.http.get<any>('v2/users/' + idUser, {params: httpParams}).pipe(
            map(value => {
                SessionLocaleService.putSync('user', value, true);
                this.subjectRqt.next('ok');
            })
        );
    }

    logger(body: any, severity = 'info', httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v1/log/' + severity, body, {params: httpParams});

    }

    getUserFromSession() {
        return SessionLocaleService.getSync('user', true);
    }

    getUserId() {
        const ref = this.getSessionRefreshToken();
        if (ref && ref.userId) {
            return ref.userId;
        }
        return null;
    }

    accounts(): Observable<any> {
        return this.http.get<any>('v1/accounts');
    }

    logoutUser(): Observable<any> {
        return of(this.deleteSessionRefreshToken());
    }

    addSessionRefreshToken(val) {
        SessionLocaleService.putSync('rf', val, true);
        this.subjectRqt.next('ok');
    }

    deleteSessionRefreshToken() {
        SessionLocaleService.deleteByKey('rf', true);
        SessionLocaleService.deleteByKey('per', true);
        SessionLocaleService.deleteByKey('user', true);
        this.deleteAccountSelected();
        this.subjectRqt.next( new Date().getTime());
        return true;
    }

    getSessionRefreshToken(): RefreshTokenResponse {
        return SessionLocaleService.getSync('rf', true);
    }

    isExpirationRefreshToken(): boolean {
        const ref = this.getSessionRefreshToken();
        if (!ref) {
            return true;
        }
        if (ref && ref.expiration) {
            const now = new Date();
            const expiration = new Date(ref.expiration);
            return expiration.getTime() <= now.getTime();
        }
        return true;
    }

    addAccountSelected(account) {
        SessionLocaleService.putSync('accountSelected', account, true);
        this.accountSub.next(account);
    }

    getAccountSelected() {
        return SessionLocaleService.getSync('accountSelected', true);
    }

    deleteAccountSelected() {
        SessionLocaleService.deleteByKey('accountSelected', true);
        this.accountSub.next(null);
    }


}
