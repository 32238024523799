export enum ConfigRouter {
    //* ADMIN**/
    DISPOSITION = 'dispositions',
    SEAT_MAP = 'seats-map',
    external_barcode = 'external-barcode',
    pages = 'pages',
    websites = 'websites',
    locations='locations',

    /** POS **/
    HISTORIC_SCAN = 'historic-scan',
    IMPORT = 'import',
    /** REPORTS **/
    dashboard = 'dashboard',
    dashboard_SALE = 'sale',
    dashboard_SALE_FULL = 'rp/sale',
    dashboard_TOP5_CITIES = 'rp/top5cities',
    REPORT = 'rp',

    /***  scaner  ***/
    scanner = 'scanner',
    scanner_events = 'events',
    scanner_event = 'events/:id',
    scanner_view = 'events/:id/scan'


}
