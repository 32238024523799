<mat-form-field style="width: 400px;padding: 10px">
    <mat-progress-bar     mode="indeterminate" *ngIf="!load"></mat-progress-bar>
    <mat-select [formControl]="entityCtrl" placeholder="{{labelTitle}}" #singleSelect [disabled]="!load">
        <mat-option>
            <ngx-mat-select-search [formControl]="entitiesFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option  [value]="null" >
             Empty
        </mat-option>

        <mat-option *ngFor="let ety of filteredEntities | async" [value]="ety"  [matTooltip]="ety[label]" matTooltipPosition="right">
            {{ety[label]}}
        </mat-option>
    </mat-select>
</mat-form-field>
<!--<pre>{{accountSelected | json}}</pre>-->


