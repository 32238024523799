import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormErrorMessageComponent} from "./form-error-message.component";
import {TranslateModule} from "@ngx-translate/core";
export * from "./form-error-message.component";

@NgModule({
    declarations: [FormErrorMessageComponent],
    exports: [FormErrorMessageComponent],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class FormErrorMessageModule {
}
