import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AppResolveService implements Resolve<any> {

    constructor(public translate: TranslateService,

                public l: Location) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {


        const lang = route.paramMap.get('lang');
        this.translate.use(lang);
        return of('ok');
    }
}


