import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { message } from 'ag-grid-community/dist/lib/utils/general';

export interface MessageAction {
    dialog: boolean;
    snackBar: {
        vrPosition: string;
        hrPosition: string;
    };
    message: string;
    translate: boolean;
}


@Injectable()
export class MessageObserverService {
    private message = new Subject<MessageAction>();

    constructor() {
    }

    watchMessage(): Observable<MessageAction> {
        return this.message.asObservable();
    }

    addMessage(messageAction: MessageAction) {
        this.message.next(messageAction);
    }
}
