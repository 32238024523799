import {Injectable} from '@angular/core';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SubdomainService} from './subdomain.service';
import {SessionRedisService} from './session-redis.service';
import {ConfigWebsite} from '../models/config-website';
import {ExactInteracFormResponse} from '../../../../../src/modules/models/exact-interac-form-response';
import {ImageSizeEnum} from '../models/image-size-enum';


@Injectable({
    providedIn: 'root'
})
export class WebsiteSaleService {
    configWebsite: ConfigWebsite;
    _openScreen = new Subject();

    constructor(private http: HttpClient, private subdomainService: SubdomainService,
                private sessionRedisService: SessionRedisService) {
    }


    getConfigWebsite(): Observable<any> {
        //  return this.http.get<any>('backend/v1/websites/' + this.subdomainService.getSubdomain())

        return this.http.get<any>('v1/websites/config')
            .pipe(
                flatMap(ws => {
                    this.configWebsite = ws;
                    return forkJoin([
                        of(ws),
                        this.sessionRedisService.getXSessionToken()]
                    );
                })
            );
    }

    getLocalConfig() {
        return this.configWebsite;
    }

    getInteracConfig(client, amount): Observable<ExactInteracFormResponse> {
        //  return this.http.get<any>('backend/v1/websites/' + this.subdomainService.getSubdomain())
        const ht = new HttpParams().set('clientId', client).set('amount', String(amount)).set('cache', '30000');
        return this.http.get<ExactInteracFormResponse>('v1/interac/exact-url', {params: ht});

    }

    uploadImage(file: File, imageSizeEnum: ImageSizeEnum, pageId?, venueId?, showId?, websiteId?, locationId?) {
        const requestUrl = `v1/image-upload`;
        let headers = new HttpParams();
        headers = headers.set('context', 'ADMIN');
        if (pageId) {
            headers = headers.set('pageId', pageId);
        }
        if (venueId) {
            headers = headers.set('venueId', venueId);
        }
        if (showId) {
            headers = headers.set('showId', showId);
        }
        if (websiteId) {
            headers = headers.set('pageId', websiteId);
        }
        if (locationId) {
            headers = headers.set('locationId', locationId);
        }
        if (imageSizeEnum) {
            headers = headers.set('dimension', imageSizeEnum);
        }

        const payload = new FormData();
        payload.append('file', file, 'file');
        return this.http.post<any>(requestUrl, payload, {params: headers});
    }

    uploadImagePage(file, imageSizeEnum: ImageSizeEnum, pageId) {
        return this.uploadImage(file, imageSizeEnum, pageId);
    }

    uploadImageLocationImage(file, imageSizeEnum: ImageSizeEnum, id) {
        return this.uploadImage(file, imageSizeEnum, null, null, null, null, id);
    }
}
