export const locale = {



    "success": {
        "success": "Success",
        "update": "Update successful",
        "save": "Save successful",
        "add": "New instance added",
        "delete": "Deleted with success",
        "fetch": "Instance fetch successfully",
        "loaded": "Please wait while the data are loaded.",
        "successUpdateCart": "These tickets has been added to your order",
        "requestPasswordReset": "Password reset request sent."
    }

};
