export const locale = {
    "save": "Sauvegarder",
    "add": "Ajouter",
    "ok": "OK",
    "cancel": "Annuler",
    "name": "Nom",
    "code": "Code",
    active:"Active",
    create:"Create",
    'title': {
        'ticket': 'Ticket',
        'about': 'About',
        'account': 'Account Settings',
        'accounts': 'Accounts',
        'admin': 'Admin',
        'cart': 'Cart',
        'changePassword': 'Change Password',
        'clients': 'Clients',
        'combos': 'Combos',
        'configuration': 'Configuration',
        'contratsStats': 'Contract Stats',
        'events': 'Events',
        'giftCertificate': 'Gift Certificates',
        'home': 'Home',
        'invoices': 'Invoices',
        'languages': 'Languages',
        'locations': 'Locations',
        'login': 'Login',
        'signUp': 'Sign up',
        'logout': 'Logout',
        'options': 'Options',
        'poweredby': 'Powered by ',
        'securedBy': 'Secured by ',
        'products': 'Products',
        'profile': 'Profile',
        'register': 'Register',
        'reports': 'Reports',
        'sales': 'Sales',
        'scanStatus': 'Scan History',
        'scan': 'Scan',
        'scanDashboard': 'Scan Dashboard',
        'scanIn': 'Scan In',
        'scanOut': 'Scan Out',
        'subscriptions': 'Subscriptions',
        'ticketPurchase': 'Ticket Purchase',
        'tickets': 'Tickets',
        'userProfile': 'My Profile',
        'website': 'Website',
        'keyServer': 'Management of queues',
        'scanBarcodeImport': 'import barcode'
    },
    'formMsg': {
        'submit': 'Soumettre',
        'notValid': 'Invalid',
        'valid': 'Valid',
        'isRequired': 'is required',
        'reserve': 'Book',
        'errors': {
            'required': 'is <strong>required</strong>',
            'maxlength': 'too long',
            'minlength': 'too short',
            'pattern': 'invalid format',
            'min': 'under minimum',
            'max': 'over maximum',
            'email': 'not an email'
        }
    },
    'client': {
        'titleList': 'Clients',
        'titleOne': 'Client',
        'client': 'client',
        'clients': 'clients',
        'id': 'ID',
        'company': 'Company',
        'title': 'Title',
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'email': 'E-mail',
        'emailConfirmation': 'Confirm your E-mail',
        'password': 'Password',
        'passwordConfirm': 'Confirm password',
        'username': 'Username',
        'comment': 'Comment',
        'prefix': 'Prefix',
        'language': 'Communication preference',
        'code': 'Code',
        'forgotPassword': 'Forgot password',
        'oldPassword': 'Old password',
        'newPassword': 'New password',
        'newPasswordConfirm': 'Confirm new password',
        'passwordsMustBeSame': 'The Confirm password and passwords must be the same',
        'address': {
            'addresses': 'Addresses',
            'title': 'Your Details',
            'address': 'Address',
            'addressLine1': 'Address 1',
            'addressLine2': 'Address 2',
            'apartment': 'Apartment',
            'country': 'Country',
            'province': 'Province',
            'state': 'State',
            'city': 'City',
            'postal': 'Postal Code',
            'zip': 'Zip Code',
            'app': 'App',
            'livraison': 'Shipping Address',
            'addressCompany': 'Company Address',
            'sameAddress': 'same address'
        },
        'phone': {
            'phones': 'Phones',
            'type': 'Type',
            'telephone': 'Phone number',
            'extension': 'Extension'
        },
        'msgFacebook': 'msg Facebook',
        'horsCanada': 'Outside Canada',
        'Canada': 'Canada',
        "success": {
            "success": "Success",
            "update": "Update successful",
            "save": "Save successful",
            "add": "New instance added",
            "delete": "Deleted with success",
            "fetch": "Instance fetch successfully",
            "loaded": "Please wait while the data are loaded.",
            "successUpdateCart": "These tickets has been added to your order",
            "requestPasswordReset": "Password reset request sent."
        },

    },
    "scan": {
        "titleList": "Scans",
        "titleOne": "Scan",
        "eventToScan": "Event to scan",
        "ScanDetail": "Scan History",
        "scanList": "Barcode scans",
        "zoneToScan": "Zone to scan",
        "gateToScan": "Gate to scan",
        "selectEvent": "Select Event",
        "selectZone": "Select Zone",
        "selectGate": "Select Gate",
        "process": "Process",
        "camera": "Camera",
        "laser": "Laser",
        "scan": "Scan",
        "errors": "Errors",
        "firstScan": "First Scans",
        "scanIn": "Scans In",
        "scanOut": "Scans Out",
        "sales": "Sales",
        "sync": "Enable offline",
        "lastSync": "Last sync",
        "never": "never",
        "scans": "Scans",
        "manual": "keypad",
        "lastScan": "Last Scan",
        "recentScans": "Recent Scans",
        "summary": "Summary",
        "statistics": "Scan statistics",
        "launch": "Launch",
        "capacity": "Capacity",
        "tixIn": "Tickets In / Sold",
        "passIn": "Passes In / Sold",
        "totalIn": "Total In",
        "justNow": "Just now",
        "barcode": "Barcode",
        "noEventToScan": "No event to scan",
        "noResult": "No result",
        "noScan": "No scan",
        "zone": "zone",
        "zones": "zones",
        "oneGate": "gate",
        "manyGates": "gates",
        "gate": "Gate",
        "gates": "Gates",
        "scanBy": "Scanned by",
        "section": "Section",
        "level": "Level",
        "row": "Row",
        "seat": "Seat",
        "isVoid": "Voided ticket",
        "isValid": "Valid ticket",
        "scanInvalid": "Invalid barcode",
        "ticket": "Ticket",
        "event": "Event",
        "client": "Customer ",
        "clientTickets": "Client Tickets",
        "nbScan": "Number of scans",
        "issueDate": "Issue date",
        "startDate": "Start date",
        "endDate": "End date",
        "btGetListEventScan": "Get list events ",
        "dateRange": "Date range",
        "today": "Today",
        "nextWeek": "Next week",
        "nextMonth": "Next month",
        "lastWeek": "Last week",
        "lastMonth": "Last month",
        "other": "Other",
        "errorScanLaser": "Le scanner laser ne fonctionne pas.",
        "import-barcode":"import barcode ",
        "slowest":"Slowest download is",
        "upload":"barcode waiting for upload",
        "statScan": {
            "ERROR": "Error",
            "FIRST_SCAN": "Scan in",
            "SCAN_OUT": "Scan out",
            "SCAN_IN": "Scan in",
            "ERROR_ALREADY_SCANNED_IN": "Already scanned",
            "ERROR_BARCODE_NOT_FOUND": "Barcode not found",
            "ERROR_NOT_IN": "The barcode of the ticket was not scan in so it can't be scanned out",
            "ERROR_ALREADY_SCANNED_OUT": "Already scanned out",
            "ERROR_BARCODE_VOIDED": "Barcode is voided",
            "ERROR_TICKET_VOIDED": "Ticket is voided",
            "ERROR_WRONG_SHOW": "Barcode of ticket is for an other show.",
            "PASS_NOT_APPLICABLE": "Pass does not apply to this show.",
            "WRONG_ZONE": "Wrong zone",
            "LIMIT_REACHED": "Liimt reached",
            "CAPACITY_REACHED": "Capacity reached",
            "TEST_BAD":"Barcode Test Bad",
            "TEST_OK":"Barcode Test Ok"
        }
    },
};
