import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageAction, MessageObserverService, SessionLocaleService } from '../../../../../../shared-lib/src/lib/services';
import { AuthenticationUserService } from '../../../../../../shared-lib/src/lib/services';
import { lastValueFrom } from 'rxjs';


@Component({
    selector: 'tw-login-user',
    templateUrl: './login-user.component.html',
    styleUrls: ['./login-user.component.css']
})
export class LoginUserComponent implements OnInit {
    load = true;
    hasRightToScanNextBarcode = true;


    constructor(private authenticationUserService: AuthenticationUserService,
                public messageObserverService: MessageObserverService,
                private sessionLocaleService: SessionLocaleService) {
    }

    ngOnInit() {
    }

    async authUser(user: NgForm | any) {
        try {
            this.load = false;
            await lastValueFrom(this.authenticationUserService.loginUser(user.value));
            await lastValueFrom(this.authenticationUserService.getUser(this.authenticationUserService.getUserId()));
            const accounts = await lastValueFrom(this.authenticationUserService.accounts());
            if (accounts && accounts.length === 1) {
                this.authenticationUserService.addAccountSelected(accounts[0]);
            }
            this.load = true;
        } catch (error1) {
            console.log(error1);
            this.load = true;
            if (error1 && error1.error && error1.error.error) {
                this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });
                return;
            }
            this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error });
        }
    }

    async startValidCamera(rfidSerial: any) {
        this.hasRightToScanNextBarcode = false;
        await this.authUser({ value: { rfidSerial: rfidSerial } });
        this.hasRightToScanNextBarcode = true;
    }
}
