import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationUserService} from '../../../../shared-lib/src/lib/services';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private authenticationUserService: AuthenticationUserService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addParam(req));
    }

    addParam(req: HttpRequest<any>) {
        const ref = this.authenticationUserService.getSessionRefreshToken();
        let ht = {};
        //console.log(ref)
        const account = this.authenticationUserService.getAccountSelected();
        if (this.authenticationUserService.getAccountSelected()) {

            if (account.id) {
                ht['accountId'] = account.id + '';
            }
            if (account.code) {
                ht['accountCode'] = account.code;
            }
        }


        if (!ref) {
            return req;
        }
        if (req.params.has('context') && req.params.get('context') === 'WEB') {
            return req;
        }

        if (Object.keys(ht).length === 0) {
            //console.log(ref);
            return req.clone(
                {
                    setHeaders: {'Authorization': 'Bearer ' + ref.refreshToken}

                });
        }
        return req.clone(
            {
                setHeaders: {'Authorization': 'Bearer ' + ref.refreshToken},
                setParams: ht
            });


    }
}
