export const locale = {
    "save": "Sauvegarder",
    "add": "Ajouter",
    "ok": "OK",
    "cancel": "Annuler",
    "name": "Nom",
    "code": "Code",
     active:"Active",
     create:"Cree",
    'title': {
        'ticket': 'Ticket',
        'about': 'About',
        'account': 'Account Settings',
        'accounts': 'Accounts',
        'admin': 'Admin',
        'cart': 'Cart',
        'changePassword': 'Change Password',
        'clients': 'Clients',
        'combos': 'Combos',
        'configuration': 'Configuration',
        'contratsStats': 'Contract Stats',
        'events': 'Events',
        'giftCertificate': 'Gift Certificates',
        'home': 'Home',
        'invoices': 'Invoices',
        'languages': 'Languages',
        'locations': 'Locations',
        'login': 'Login',
        'signUp': 'Sign up',
        'logout': 'Logout',
        'options': 'Options',
        'poweredby': 'Powered by ',
        'securedBy': 'Secured by ',
        'products': 'Products',
        'profile': 'Profile',
        'register': 'Register',
        'reports': 'Reports',
        'sales': 'Sales',
        'scanStatus': 'Scan History',
        'scan': 'Scan',
        'scanDashboard': 'Scan Dashboard',
        'scanIn': 'Scan In',
        'scanOut': 'Scan Out',
        'subscriptions': 'Subscriptions',
        'ticketPurchase': 'Ticket Purchase',
        'tickets': 'Tickets',
        'userProfile': 'My Profile',
        'website': 'Website',
        'keyServer': 'Management of queues',
        'scanBarcodeImport': 'import barcode'
    },
    'formMsg': {
        'submit': 'Submit',
        'notValid': 'Invalid',
        'valid': 'Valid',
        'isRequired': 'is required',
        'reserve': 'Book',
        'errors': {
            'required': 'is <strong>required</strong>',
            'maxlength': 'too long',
            'minlength': 'too short',
            'pattern': 'invalid format',
            'min': 'under minimum',
            'max': 'over maximum',
            'email': 'not an email'
        }
    },
    'client': {
        'titleList': 'Clients',
        'titleOne': 'Client',
        'client': 'client',
        'clients': 'clients',
        'id': 'ID',
        'company': 'Company',
        'title': 'Title',
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'email': 'E-mail',
        'emailConfirmation': 'Confirm your E-mail',
        'password': 'Password',
        'passwordConfirm': 'Confirm password',
        'username': 'Username',
        'comment': 'Comment',
        'prefix': 'Prefix',
        'language': 'Communication preference',
        'code': 'Code',
        'forgotPassword': 'Forgot password',
        'oldPassword': 'Old password',
        'newPassword': 'New password',
        'newPasswordConfirm': 'Confirm new password',
        'passwordsMustBeSame': 'The Confirm password and passwords must be the same',
        'address': {
            'addresses': 'Addresses',
            'title': 'Your Details',
            'address': 'Address',
            'addressLine1': 'Address 1',
            'addressLine2': 'Address 2',
            'apartment': 'Apartment',
            'country': 'Country',
            'province': 'Province',
            'state': 'State',
            'city': 'City',
            'postal': 'Postal Code',
            'zip': 'Zip Code',
            'app': 'App',
            'livraison': 'Shipping Address',
            'addressCompany': 'Company Address',
            'sameAddress': 'same address'
        },
        'phone': {
            'phones': 'Phones',
            'type': 'Type',
            'telephone': 'Phone number',
            'extension': 'Extension'
        },
        'msgFacebook': 'msg Facebook',
        'horsCanada': 'Outside Canada',
        'Canada': 'Canada',
        "success": {
            "success": "Succès",
            "update": "Enregistrement réussi",
            "save": "Enregistrement réussie",
            "add": "Création réussie",
            "delete": "Effacer avec succès",
            "fetch": "Instance retrouvée",
            "loaded": "Veuillez patienter pendant le téléchargement des données",
            "successUpdateCart": "Ces billets a bien été ajouté à votre panier.",
            "requestPasswordReset": "Requête de réinitialisation du mot de passe envoyée."
        }
    },
    "scan": {
        "titleList": "Scans",
        "titleOne": "Scan",
        "eventToScan": "Événement à scanner",
        "ScanDetail": "Historique de scans",
        "scanList": "Scans d'un code à barres",
        "zoneToScan": "Zone à scanner",
        "gateToScan": "Porte à scanner",
        "selectEvent": "Choisissez l'événement",
        "selectZone": "Choisissez la zone",
        "selectGate": "Choisissez la porte",
        "process": "Traiter",
        "camera": "Caméra",
        "laser": "Laser",
        "scan": "Scan",
        "errors": "Erreurs",
        "firstScan": "Premières entrées",
        "scanIn": "Entrées",
        "scanOut": "Sorties",
        "sales": "Ventes",
        "sync": "Active synchro",
        "lastSync": "Dernière synchro",
        "never": "jamais",
        "scans": "Scans",
        "manual": "Clavier",
        "lastScan": "Dernier scan",
        "recentScans": "Derniers scans",
        "summary": "Sommaire",
        "statistics": "Statistiques d'accès",
        "launch": "Lancer",
        "capacity": "Capacité",
        "tixIn": "Billets entrés / vendus",
        "passIn": "Passes entrées / vendues",
        "totalIn": "Total entrés",
        "justNow": "À l'instant",
        "barcode": "Code à barres",
        "noEventToScan": "Aucun événement à scanner",
        "noResult": "Aucun résultat",
        "noScan": "Aucun scan",
        "zone": "zone",
        "zones": "zones",
        "oneGate": "porte",
        "manyGates": "portes",
        "gate": "Porte",
        "gates": "Portes",
        "scanBy": "Scanné par",
        "section": "Section",
        "level": "Niveau",
        "row": "Rangée",
        "seat": "Siège",
        "isVoid": "Billet annulé",
        "isValid": "Billet valide",
        "scanInvalid": "Code à barres invalide",
        "ticket": "Billet",
        "event": "Événement",
        "client": "Client ",
        "clientTickets": "Billets du client",
        "nbScan": "Nombre de scans",
        "issueDate": "Date d'émission",
        "startDate": "Date début",
        "endDate": "Date fin",
        "btGetListEventScan": "Liste d'événement à scanner",
        "dateRange": "Période",
        "today": "Aujourd'hui",
        "nextWeek": "Semaine prochaine",
        "nextMonth": "Mois prochain",
        "lastWeek": "Semaine dernière",
        "lastMonth": "Mois dernier",
        "other": "Autre",
        "errorScanLaser": "Le scanner laser ne fonctionne pas.",
        "import-barcode":"import barcode ",
        "slowest":"Téléchargement le plus lent est",
        "upload":"Code à barres en attente de téléchargement",
        "statScan": {
            "ERROR": "Erreur ",
            "FIRST_SCAN": "Scan entrée",
            "SCAN_OUT": "Scan entrée",
            "SCAN_IN": "Scan sortie ",
            "ERROR_ALREADY_SCANNED_IN": "Billet déjà scanné",
            "ERROR_BARCODE_NOT_FOUND": "Billet inconnu",
            "ERROR_NOT_IN": "Le code-barre du ticket n'a pas été scanné à la rentrée, il ne peut donc pas être scanné",
            "ERROR_ALREADY_SCANNED_OUT": "Déjà sortie",
            "ERROR_BARCODE_VOIDED": "Ce code à barres est annulé",
            "ERROR_TICKET_VOIDED": "Le billte est annulé",
            "ERROR_WRONG_SHOW": "Ce code barre est valide pour un autre spectacle.",
            "PASS_NOT_APPLICABLE": "cette passe ne s'applique pas à ce spectacle.",
            "WRONG_ZONE": "Mauvaise zone",
            "LIMIT_REACHED": "Limite atteinte",
            "CAPACITY_REACHED": "Capacité atteinte",
            "TEST_BAD": "Barcode test BAD",
            "TEST_OK": "Barcode test OK",

        }
    },
};
