import {Injectable} from '@angular/core';
import {HttpRequest, HttpResponse} from '@angular/common/http';


export interface RequestCacheEntry {
    url: string;
    response: HttpResponse<any>;
    lastRead: number;
    maxAge: number;
}

@Injectable()
export abstract class RequestCache {
    abstract get(req: HttpRequest<any>): HttpResponse<any> | undefined;

    abstract put(req: HttpRequest<any>, response: HttpResponse<any>): void;
}

const maxAge = 60 * 1000; // maximum cache age (ms)

@Injectable()
export class RequestCacheWithMap implements RequestCache {

    cache = new Map<string, RequestCacheEntry>();

    constructor() {
    }

    get(req: HttpRequest<any>): HttpResponse<any> | undefined {
        const url = req.urlWithParams;
        const cached = this.cache.get(url);

        if (!cached) {
            return undefined;
        }
        const maxCache = <any> req.params.get('cache') || maxAge;
        const isExpired = cached.lastRead < (Date.now() - maxCache);
        const expired = isExpired ? 'expired ' : '';
        console.log(`Found ${expired}cached response for "${url}".`);
        return isExpired ? undefined : cached.response;
    }

    put(req: HttpRequest<any>, response: HttpResponse<any>): void {
        const url = req.urlWithParams;

        //  this.messenger.add(`Caching response from "${url}".`);

        const ent = <RequestCacheEntry>{url, response, lastRead: Date.now()};
        this.cache.set(url, ent);

        // remove expired cache entries
        const maxCache = <any> req.params.get('cache') || maxAge;
        const expired = Date.now() - maxCache;
        this.cache.forEach(entr => {
            if (entr.lastRead < expired) {
                this.cache.delete(entr.url);
            }
        });

        //  this.messenger.add(`Request cache size: ${this.cache.size}.`);
    }
}

