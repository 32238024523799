import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingAdminModule } from './app-routing-admin.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { locale as fr } from './i18n/fr';
import { locale as en } from './i18n/en';
import { SHARED_INTERCEPTORS_ADMIN, SHARED_PROVIDE } from '../../../shared-lib/src/lib/interceptors';
import { PageNotFoundModule } from '../../../shared-lib/src/lib/component/page-not-found/page-not-found.module';
// @ts-ignore
import {LanguageModule, MessageModule, NotAuthorizedModule} from '../../../shared-lib/src/lib/component';
import { PROJECT_NAME, ProjectNameEnum } from '../../../shared-lib/src/lib/utils/utilsShared';
import {MatDividerModule} from '@angular/material/divider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {ListEntityModule} from '../../../shared-lib/src/lib/component/list-entity/list-entity.module';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingAdminModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatProgressBarModule,
        MatIconModule,
        TranslateModule.forRoot(),
        PageNotFoundModule,
        NotAuthorizedModule,
        MessageModule,
        LanguageModule,
        MatDividerModule,
        MatToolbarModule,
        MatMenuModule,
        MatSidenavModule,
        ListEntityModule

    ],
    providers: [...SHARED_PROVIDE, ...SHARED_INTERCEPTORS_ADMIN, { provide: PROJECT_NAME, useValue: ProjectNameEnum.ADMIN }],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(private translate: TranslateService) {
        this.translate.setDefaultLang('en');
        this.translate.setTranslation('fr', fr, true);
        this.translate.setTranslation('en', en, true);
    }
}
