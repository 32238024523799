import { ApiInterceptorService } from './api-interceptor.service';
import { AuthInterceptorService } from './auth-interceptor.service';
import { SessionSaleInterceptorService } from './session-sale.interceptor.service';
export * from './api-interceptor.service';
export * from './auth-interceptor.service';
export * from './session-sale.interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CachingInterceptor } from './caching-interceptor.service';
import { RequestCacheWithMap, RequestCache } from '@shared-lib/services';
import { SessionLocaleService } from '@shared-lib/services';
import { MessageObserverService } from '@shared-lib/services';
import { AuthenticationUserService } from '@shared-lib/services';
import { LangsObserverService } from '@shared-lib/services';
import { SessionRedisService } from '@shared-lib/services';
import { ZoomService } from '../component/control-zoom/zoom.service';
import { AuthInterceptorSessionService } from './auth-interceptor-session.service';


export const SHARED_INTERCEPTORS_SALE: any [] = [
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionSaleInterceptorService, multi: true }
];
export const SHARED_INTERCEPTORS_ADMIN: any [] = [
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
];
export const SHARED_INTERCEPTORS_POS: any [] = [
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorSessionService, multi: true }
];
export const SHARED_INTERCEPTORS_SCAN: any [] = [
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorSessionService, multi: true }
];
// export const SHARED_PROVIDE: any [] = [
//     { provide: RequestCacheWithMap, useClass: RequestCacheWithMap, multi: true },
//     { provide: RequestCache, useClass: RequestCache, multi: true },
//     { provide: SessionLocaleService, useClass: SessionLocaleService, multi: true },
//     { provide: MessageObserverService, useClass: MessageObserverService, multi: true },
//     // { provide: AuthenticationUserService, useClass: AuthenticationUserService, multi: true },
//
// ];
export const SHARED_PROVIDE: any [] = [
    RequestCacheWithMap,
    RequestCache,
    SessionLocaleService,
    MessageObserverService,
    AuthenticationUserService,
    LangsObserverService,
    SessionRedisService,

];


