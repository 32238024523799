<!--</select>-->
<!--{{ languageSupported | json}}-->
<!--gg{{ translate.currentLang }}-->
<mat-form-field *ngIf="languageSupported.length>2">
    <mat-select placeholder="lang" [(ngModel)]="selectedValue"  (selectionChange)="selectChange(selectedValue)">
        <mat-option *ngFor="let lang of languageSupported" [value]="lang" >
            {{ lang.toUpperCase() }}
        </mat-option>
    </mat-select>
</mat-form-field>
<div  *ngIf="languageSupported.length<=2 && languageSupported.length >1" class="tw-padding-10">
    <button  mat-raised-button color="primary"  class="tw-primary" *ngIf="selectedValue === 'en'" (click)="selectChange('fr')">fr</button>
    <button mat-raised-button color="primary" class="tw-primary" *ngIf="selectedValue === 'fr'" (click)="selectChange('en')">en</button>
</div>
