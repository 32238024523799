import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DialogMsgComponent } from './dialog-msg/dialog-msg.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { MessageObserverService } from '../../services/message-observer.service';


@Component({
    selector: 'lib-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
    setAutoHide = true;
    autoHide = 1500;
    addExtraClass = false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(public dialog: MatDialog,
                private messageObserverService: MessageObserverService,
                public snackBar: MatSnackBar) {

    }

    ngOnInit() {
        console.log('MessageComponent');
        this.messageObserverService.watchMessage().subscribe(value => {
            console.log(value);
            // this.snackBar.openFromComponent(SnackBarComponent, this._createConfig(value));
            if (value.dialog) {
                this.dialog.open(DialogMsgComponent, {
                    data: {
                        message: value.message,
                        translate: value.translate
                    }
                });
            }
            if (value.snackBar) {
                this.snackBar.openFromComponent(SnackBarComponent, this._createConfig(value));
            }

        });

    }

    private _createConfig(data: any) {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        // config.panelClass = this.addExtraClass ? ['demo-party'] : undefined;
        // config.direction = 500;
        config.panelClass = 'tw-snack-bar';
        config.data = data;
        return config;
    }

}
