import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginUserComponent} from './login-user/login-user.component';
import {UserForgotPasswordComponent} from './user-forgot-password/user-forgot-password.component';

import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormErrorMessageModule} from "../form-error-message/form-error-message.module";
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { NfcScannerModule } from '../nfc-scanner/nfc-scanner.module';

export * from './login-user/login-user.component';
export * from './user-forgot-password/user-forgot-password.component';

export const routesAdmin: Routes = [
    {  path: '',
        component: LoginUserComponent
    },
    {  path: 'forget-password',
        component: UserForgotPasswordComponent
    }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routesAdmin),
    TranslateModule,
    FormErrorMessageModule,
    MatFormFieldModule,
    MatInputModule, MatButtonModule, NfcScannerModule
  ],
    declarations: [LoginUserComponent, UserForgotPasswordComponent],
    exports: [LoginUserComponent, UserForgotPasswordComponent]
})
export class UsersModule {
}
