import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionLocaleService} from './session-locale.service';
import {map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SessionRedisService {

    constructor(private http: HttpClient,
                private sessionLocaleService: SessionLocaleService,
                protected route: ActivatedRoute,
    ) {
    }

    getXSessionToken(): Observable<any> {

        let ht = new HttpParams().set('cache', '0');
        this.transformToAssocArray(window.location.search).forEach(value => {
            ht = ht.set(value.key, value.val);
            if(value.key=== 'auto'){
                this.removeSessionLocal()
            }
        });
        console.log(ht);

        return this.http.get<any>('v1/session', {params: ht})
            .pipe(
                map(s => {
                    this.addSessionLocal(s.sessionToken);
                    return s;
                })
            );
    }

    addSessionLocal(val) {
        SessionLocaleService.putSync('sessionToken', val, true);
    }

    removeSessionLocal() {
        SessionLocaleService.deleteByKey('sessionToken', true);
    }

    getXSessionLocal(): string {
        return SessionLocaleService.getSync('sessionToken', true);
    }

    transformToAssocArray(prmstr: string): any[] {

        const params = [];
        if (!prmstr) {
            return params;
        }
        prmstr = prmstr.replace('?', '');

        const prmarr = prmstr.split('&');
        for (let i = 0; i < prmarr.length; i++) {
            const tmparr = prmarr[i].split('=');
            if (tmparr && tmparr.length === 2) {
                params.push({key: String(tmparr[0]), val: tmparr[1]});
            }
        }
        return params;
    }
}
