<form #user="ngForm" style="margin: auto;" (ngSubmit)="authUser(user)" class="tw-max-width-6 tw-padding-10">
    <mat-form-field class="tw-input">
        <input matInput placeholder="{{'client.email' | translate}}" ngModel required name="username"
               #username="ngModel">
        <mat-error>
            <tw-form-error-message [errors]="username.errors"></tw-form-error-message>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="tw-input">
        <input matInput type="password"    placeholder="{{'client.password' | translate}}" ngModel required name="password">
        <mat-error>
            is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" [disabled]="load && !user.valid " style="width: 100%">
        {{ 'title.login' |
        translate }}
        <div class="cssload-speeding-wheel" *ngIf="!load"></div>
    </button>

    <lib-tw-nfc-scanner (barcodeData)="startValidCamera($event)" [startScannerBarcode]="hasRightToScanNextBarcode"></lib-tw-nfc-scanner>

    <!--<a mat-button color="primary" routerLink="../client-forgot-password"> {{ 'client.forgotPassword' | translate-->
        <!--}} </a>-->

</form>
