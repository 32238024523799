export const locale = {

    "success": {
        "success": "Succès",
        "update": "Enregistrement réussi",
        "save": "Enregistrement réussie",
        "add": "Création réussie",
        "delete": "Effacer avec succès",
        "fetch": "Instance retrouvée",
        "loaded": "Veuillez patienter pendant le téléchargement des données",
        "successUpdateCart": "Ces billets a bien été ajouté à votre panier.",
        "requestPasswordReset": "Requête de réinitialisation du mot de passe envoyée."
    }

};
