import {Component, Input, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

import {ActivatedRoute} from '@angular/router';
import {HelpsService} from '../../../utils/helps.service';

@Component({
    selector: 'tw-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {


    @Input() languageSupported: string[] = [];
    @Input() appType: string = 'sale';
    selectedValue: string;


    constructor(public translate: TranslateService,
                public helpsService: HelpsService,
                private activatedRoute: ActivatedRoute) {
        if (!this.languageSupported) {
            this.languageSupported = [];
            this.languageSupported.push('en');
            this.languageSupported.push('fr');
        }
        this.selectedValue = this.translate.currentLang;
        console.log(this.translate.currentLang);
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {

            this.selectedValue = event.lang;
        });
    }

    ngOnInit() {
        let url = window.location.href;
        let lang = url.search('/fr/') !== -1 ? 'fr' : 'en';

        this.selectedValue = lang;
        this.translate.use(lang);
        console.log(this.translate.currentLang);
    }

    selectChange(val) {
        console.log(val);
        this.translate.use(val);
        if (this.appType === 'sale') {
            this.helpsService.selectChange(val);
        }

    }
}
