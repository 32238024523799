import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListEntityComponent } from './list-entity/list-entity.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatOptionModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatTooltipModule} from '@angular/material/tooltip';



@NgModule({
    declarations: [ListEntityComponent],
    exports: [
        ListEntityComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatOptionModule,
        ReactiveFormsModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatTooltipModule
    ]
})
export class ListEntityModule { }
