import { DatePipe } from '@angular/common';
import { Sta } from '../models/address';
import { constSystem } from './const-system';
import { any } from 'codelyzer/util/function';
import {RangeDates} from '../component';

export const PROJECT_NAME = 'PROJECT_NAME';

export enum ProjectNameEnum {
    ADMIN,
    POS,
    SALE,
    REPORT
}

export const delayTemp = ms => new Promise(resolve => setTimeout(resolve, ms));

export const datePipeAction = (value, format?, translate?) => {

    const datePipe = new DatePipe(translate.currentLang);
    return datePipe.transform(value, format, '', translate.currentLang);
};
export const getAbrCountry = (val: string): string => {
    const country = <Sta[]>constSystem.world.countries;
    const statesUS = <Sta[]>constSystem.world.states;
    const statesCAD = <Sta[]>constSystem.world.provinces;
    if (!val) {
        return 'CA';
    }
    val = val.toLowerCase();
    const temp = country.find(value => value.name.toLowerCase() === val || value.ab.toLowerCase() === val);
    if (temp) {
        return temp.ab;
    }
    return '';
};

export const getAbrProvince = (val: string, isUsa?: boolean): string => {
    let temp: Sta = <Sta>{};
    const country = <Sta[]>constSystem.world.countries;
    const statesUS = <Sta[]>constSystem.world.states;
    const statesCAD = <Sta[]>constSystem.world.provinces;
    temp.ab = '';
    if (!val) {
        return temp.ab;
    }
    val = val.toLowerCase();

    if (isUsa) {
        temp = statesUS.find(value =>
            value.nameEn.toLowerCase() === val
            || value.ab.toLowerCase() === val);
    } else {
        temp = statesCAD.find(value =>
            value.nameEn.toLowerCase() === val
            || value.nameFr.toLowerCase() === val
            || value.ab.toLowerCase() === val);
    }
    if (temp) {
        return temp.ab;
    } else {
        return '';
    }

};
export const getRangesDate = (type): RangeDates => {
    const someDate = new Date();
    let rangeDates: RangeDates = {
        startDate: new Date(),
        endDate: new Date()
    };
    switch (type) {
        case 'today':
            rangeDates.startDate = someDate;
            rangeDates.endDate = someDate;

            break;
        case 'nextWeek':
            someDate.setDate(someDate.getDate() + 7);
            rangeDates.endDate = someDate;

            break;
        case 'nextMonth':
            someDate.setDate(someDate.getDate() + 31);

            rangeDates.endDate = someDate;

            break;
        case 'previousWeek':
            someDate.setDate(someDate.getDate() - 7);
            rangeDates.startDate = someDate;


            break;
        case 'previousMonth':
            someDate.setDate(someDate.getDate() - 31);
            rangeDates.startDate = someDate;

            break;
        default:
            rangeDates = null;

            break;
    }
    return rangeDates;
};
export const injectCss = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('link');
        script.href = src;
        script.rel = 'stylesheet';
        script.addEventListener('load', resolve);
        script.addEventListener('error', () => reject('Error loading script.'));
        script.addEventListener('abort', () => reject('Script loading aborted.'));
        document.head.appendChild(script);
    });
};
export const injectScript = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        script.addEventListener('load', resolve);
        script.addEventListener('error', () => reject('Error loading script.'));
        script.addEventListener('abort', () => reject('Script loading aborted.'));
        document.head.appendChild(script);
    });
};

export const getGeolocation = (): Promise<GeolocationPosition> => {

    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                    if (position) {
                        resolve(position);
                    }
                },
                (error: GeolocationPositionError) => reject(error)
            );
        } else {
            reject('Geolocation is not supported by this browser.');
        }
    });
};
