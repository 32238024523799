import {Inject, Injectable, Optional} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {SubdomainService, WebsiteSaleService, AuthenticationUserService} from '../../lib/services';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@environments';
import {PROJECT_NAME, ProjectNameEnum} from '../utils/utilsShared';


@Injectable({
    providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {

    constructor(private subdomainService: SubdomainService,
                private websiteSaleService: WebsiteSaleService,
                private authenticationUserService: AuthenticationUserService,
                public translate: TranslateService,
                @Optional() @Inject(PROJECT_NAME) private projectName: ProjectNameEnum | any) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addConfigApiURL(req));
    }

    addConfigApiURL(req: HttpRequest<any>) {
        let url;
        const appRoute = document.querySelector('tw-root');
        if (appRoute) {
            url = appRoute.getAttribute('url');
        }
        //console.log(req.url)
        if (req.url.search('http') !== -1 || req.url.search('assets') !== -1
            && req.url.search('companion') !== -1) {

            if (req.url.search('assets') !== -1) {
                const urlOrg = req.url.replace('./', '/');
                if (url && req.url.search('assets') !== -1) {
                    return req.clone(
                        {
                            url: 'http://' + url + urlOrg
                        });
                }
            }


            return req;
        }
        const ht = {};

        ht['language'] = this.translate.currentLang;
        if (this.websiteSaleService.getLocalConfig()) {

            if (this.subdomainService.getSubdomain(url) &&  !req.params.get('subdomain')) {
                ht['subdomain'] = this.subdomainService.getSubdomain(url);
            }
            // if (req.url.search('context=WEB') === -1) {
            //     ht['context'] = 'WEB';
            // }

            if (this.websiteSaleService.getLocalConfig().accountId) {
                ht['accountId'] = this.websiteSaleService.getLocalConfig().accountId + '';
            }
            if (this.websiteSaleService.getLocalConfig().accountCode) {
                ht['accountCode'] = this.websiteSaleService.getLocalConfig().accountCode;
            }
        }
        let newUrlCall = environment.urlApi;
        if (this.projectName !== null) {
            // console.log(this.projectName);
            if (newUrlCall.search('billetterieimperialbell') !== -1) {
                const http = 'https://';
                let newUrl = newUrlCall.replace(http, '');
                newUrl = http + 'www.' + newUrl;
                //  console.log(newUrl, environment.urlApi);
                newUrlCall = newUrl;
            }
        }


        return req.clone(
            {
                url: newUrlCall + req.url,
                setParams: ht
            }
        );

    }

}
