import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationUserService } from './authentication-user.service';
import { PermissionUserService } from './permission-user.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationUserGuard implements CanActivate {
    constructor(private authService: AuthenticationUserService,
                private per: PermissionUserService,
                private router: Router,
                private translate: TranslateService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.per.getPermission().pipe(map(value => {
                console.log(value)
                if (!value) {
                    this.router.navigate([this.translate.currentLang + '/not-authorized']);
                }
                return value;

            })
        );
    }
}
